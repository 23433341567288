.Input {
    width: 100%;
    padding: 0.3rem;
    box-sizing: border-box;
    font-family: "Quicksand";
    display: flex;
    color: #ccc;
    justify-content: center;
}

.Input ::placeholder {
    color: #ccc;
}

.Label {
    font-weight: bold;
    display: block;
}

.InputElement {
    outline: none;
    display: flex;
    width: 100%;
    border: 1px solid #cccccc;
    background-color: #fff;
    padding: 1rem 2rem;
    box-sizing: border-box;
    border-radius: 2rem;
    font-size: 0.8rem;
    font-family: "Quicksand";
    font-weight: bold;
    color: #5fc6f0;
}

.InputElement:focus {
    outline: none;
}

.Touched {
    color: #5fc6f0;
    border: 1px solid #5fc6f0;
}

.Invalid {
    border: 1px solid #47267f;
    color: #47267f;
}

.Input .Invalid::placeholder {
    color: #47267f !important
}

.SendToFax {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;
    border: none;
    padding: 0;
    margin: 0;
}

.SendToFax input {
    height: 0;
    width: 0;
}

.SendToFax.none {
    display: none;
}
