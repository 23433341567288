* {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// ------ COlor
$white: #fff;
$black: #131517;
$grey: #ddd;
$grey-light: #f3f3f5;
$red: #e34e47;
$blue: #409ad7;
$green: #34ba9c;
$yellow: #efc241;
$bg-color: #fdfdfd;

// ------ Breakpoints
$breakpoint-md: 600px;
$breakpoint-lg: 900px;
$breakpoint-xl: 1200px; 
$breakpoint-xxl: 1800px;
$breakpoint-xxxl: 2100px;

@mixin md() { @media (min-width: $breakpoint-md) { @content; } }
@mixin lg() { @media (min-width: $breakpoint-lg) { @content; } }
@mixin xl() { @media (min-width: $breakpoint-xl) { @content; } }
@mixin xxl() { @media (min-width: $breakpoint-xxl) { @content; } }
@mixin xxxl() { @media (min-width: $breakpoint-xxxl) { @content; } }

* {
  -webkit-tap-highlight-color: transparent;
}

.LogoIntroFonctionnalites {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  a, img {
    visibility: visible;
    cursor: pointer;
    text-align: center;
  }
}

.StickyText {
  bottom: 0;
  left: 0;
  max-width: 54rem;
  opacity: 0;
  padding: 0 2rem;
  position: fixed;
  width: 32%;
  /* mix-blend-mode: screen;
  filter: contrast(0);  */
   

  @include md {
    left: auto;
    padding: 0;
  }

  h2 {
    margin: 0;
    transform: translateY(50%);
    font-family: 'Quicksand';
    font-size: 2.5rem;
  }
}
.StickyImg {
  bottom: 0;
  left: 0;
  max-width: 54rem;
  opacity: 0;
  padding: 0 2rem;
  position: fixed;
  width: 30%;
}

.StickyCard {
  bottom: 0;
  left: 0;
  max-width: 54rem;
  opacity: 0;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
}

.StickyText-trigger {
 /*  margin-top: 50vh; */
}

.carrousselSlide {

  width: 100%;
}

.cardSlider {
  margin-bottom: 2em;
  width: 100%;
  transform: scale(0.8);
  padding-top: 1.5em;
}

.iconSlider {
  width: 100%;
  transform: scale(0.8);
}

.iconSlider2 {
  width: 93%;
  transform: scale(0.8);
  margin: 0 auto;
}
// https://codepen.io/BuiltByEdgar/pen/jWOVYQ
.switch-container {
//
	//-webkit-transform: translate3d( -50%, -50%, 0 );
	//transform: translate3d( -50%, -50%, 0 );
}

.slick-list {
  overflow: visible;
}

.pictoCarroussel {
  position: relative;
  top: -34px;
}
.cardCarroussel {
  position: relative;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -10px 5px;
  padding: 0;
  cursor: pointer;
  transform: scale(2);
}

.slick-dots {
  height: 0px;
}

.visible-content {
  animation-duration: .3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
}

.hidden-content {
  animation-duration: .3s;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
        opacity: 0;
    }
  }
}

.active-link {
  color: #47267f !important;
  cursor:pointer;

}

.active-link-decoration {
  height: 3px;
  width: 7rem;
  background: #84c4f0;
  border-radius: 2px;
  margin-bottom: 8px;
  position: absolute;
  bottom: 1px;
  cursor:pointer;
}

.active-link-decoration-feature {
  height: 3px;
  width: 7rem;
  background: #84c4f0;
  border-radius: 2px;
  margin-bottom: 8px;
  position: absolute;
  bottom: 1px;
  cursor:pointer;
}

.active-link-decoration-who {
  height: 3px;
  width: 7rem;
  background: #84c4f0;
  border-radius: 2px;
  margin-bottom: 8px;
  position: absolute;
  bottom: 1px;
  cursor:pointer;
}

.active-contact-link {
  background: #472a7d !important;
  color: white !important;
  transition: all 0.5s;
  cursor:pointer;
}

.leftPictOffset {
  width: 50vw;
  display: flex;
  justify-content: center;
}