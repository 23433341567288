.Button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    color: white;
    padding: 1rem 2rem;
    box-sizing: border-box;
    border-radius: 2rem;
    font-size: 1rem;
    text-align: center;
    font-family: "Quicksand";
}

.Button {
    margin: 0.25rem auto;
}

.Success {
    color: white;
}

.Danger {
    color: white;
    font-size: .8rem;
}

.Switch {
    color: royalblue;
    font-size: .8rem;
    font-weight: 600;
}

.Button:disabled {
    color: rgb(255, 255, 255);
    background: #5bbbe29c;
}